import { Button, Input, useVirtualizer } from '@finalytic/components';
import { useMe, useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  Box,
  Group,
  LoadingOverlay,
  Radio,
  Skeleton,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import {
  getListingAddress,
  getListingName,
  whereListings,
} from '@vrplatform/ui-common';
import { useRef, useState } from 'react';

type SelectedListing = {
  id: string;
  title: string;
};

export const OnboardingSelectListingRoute = () => {
  const [selectListing, setSelectListing] = useState<SelectedListing | null>(
    null
  );

  return (
    <>
      <ListingSelect
        selectListing={selectListing}
        setSelectListing={setSelectListing}
      />

      <Button
        variant="primary"
        sx={(theme) => ({
          width: '100%',
          maxWidth: 500,
          marginBottom: theme.spacing.md,
        })}
        disabled={!selectListing}
      >
        Start product tour{selectListing && ` with ${selectListing.title}`}
      </Button>
      <Button
        leftIcon={'Redo2Icon'}
        variant="light"
        sx={{
          width: '100%',
          maxWidth: 500,
        }}
      >
        Skip product tour
      </Button>
    </>
  );
};

const ListingSelect = ({
  selectListing,
  setSelectListing,
}: {
  selectListing: SelectedListing | null;
  setSelectListing: (id: SelectedListing) => void;
}) => {
  const [teamId] = useTeamId();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');

  const parentRef = useRef<HTMLDivElement>(null);

  const queryData = useQuery(
    (q, args) => {
      const where = whereListings({
        currentTeamId: 'fa4d1a20-2f47-4021-9c72-c5a809be9c48',
        dashboard: 'propertyManager',
        GL: true,
        meId: args.meId,
        search: args.search,
        partnerTeamIds: [],
      });
      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
        })
        .map((listing) => ({
          id: listing.id,
          title: getListingName(listing),
          address: getListingAddress(listing).full,
        }));

      return {
        list,
      };
    },
    {
      keepPreviousData: true,
      variables: {
        teamId,
        search: search?.trim(),
        meId,
      },
    }
  );

  const listings = queryData.data?.list || [];

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: listings.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
  });

  const items = rowVirtualizer.getVirtualItems();

  return (
    <Box
      maw={500}
      w="100%"
      sx={(theme) => ({
        marginTop: theme.spacing.xl,
        marginBottom: rem(60),
        borderRadius: theme.radius.md,
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Box>
        <Input
          styles={(theme) => ({
            input: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderColor: theme.colors.gray[3],
            },
          })}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search listings ..."
          leftSection={
            <Icon
              color={(theme) => theme.colors.gray[4]}
              icon="SearchIcon"
              size={18}
            />
          }
        />
      </Box>
      <Box
        ref={parentRef}
        sx={(theme) => ({
          height: '240px',
          width: '100%',
          overflow: 'auto', // Make it scroll!
          border: `1px solid ${theme.colors.gray[3]}`,
          borderTop: 'none',
          borderBottomRightRadius: theme.radius.md,
          borderBottomLeftRadius: theme.radius.md,
        })}
      >
        {/* The large inner element to hold all of the items */}
        <Box
          sx={() => ({
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          })}
        >
          <LoadingOverlay
            visible={queryData.isLoading}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 400,
            }}
          />

          {queryData.isLoading && !listings.length && (
            <Stack pt="xs" px="sm">
              <Skeleton h={50} />
              <Skeleton h={50} />
              <Skeleton h={50} />
              <Skeleton h={50} />
              <Skeleton h={50} />
              <Skeleton h={50} />
            </Stack>
          )}

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {/* Only the visible items in the virtualizer, manually positioned to be in view */}
            {items.map((virtualItem) => {
              const index = virtualItem.index;

              const listing = listings[index];

              const isChecked = listing.id === selectListing?.id;

              const handleClick = () => setSelectListing(listing);

              return (
                <Box
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={rowVirtualizer.measureElement}
                >
                  <Group wrap="nowrap" gap={'sm'} pl="sm" pr={5} py={5}>
                    <Radio
                      checked={isChecked}
                      onClick={handleClick}
                      variant="filled"
                    />
                    <Box onClick={handleClick}>
                      <Text>{listing.title}</Text>
                      {listing.address && (
                        <Text size="xs" c="gray">
                          {listing.address}
                        </Text>
                      )}
                    </Box>
                  </Group>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { CountBagde } from '@finalytic/components';
import { useDashboard, useQuery, useTeamId } from '@finalytic/data';
import { wherePartnerConnectionErrors } from './useWherePartnerConnectionErrors';

type Props = {
  type?: 'errors' | 'outdated';
};

const usePartnerConnectionErrorsAggregateQuery = ({ type }: Props) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  return useQuery(
    (q, { teamId, dashboard, type }) => {
      const where = wherePartnerConnectionErrors({
        appId: undefined,
        partnerId: teamId,
        search: undefined,
        tenantId: undefined,
        dashboard,
        status: 'active',
      });

      const errors = q.connectionAggregate({ where }).aggregate?.count() || 0;

      const outdated = teamId
        ? q
            .issueConnectionsNeedVersionUpgrade({
              args: {
                id: teamId,
              },
            })
            .map((x) => ({
              id: x.connectionId,
              updatedAt: x.connectionUpdatedAt,
            }))?.length || 0
        : 0;

      if (type === 'outdated') {
        return outdated;
      }

      if (type === 'errors') {
        return errors;
      }

      return errors + outdated;
    },
    {
      queryKey: 'connections',
      variables: {
        teamId,
        dashboard,
        type,
      },
    }
  );
};

export const PartnerConnectionErrorAggregateBadge = (props: Props) => {
  const { data, isInitialLoading } =
    usePartnerConnectionErrorsAggregateQuery(props);

  return (
    (!!data || isInitialLoading) && (
      <CountBagde count={data || 0} loading={isInitialLoading} />
    )
  );
};

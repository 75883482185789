import {
  useDashboard,
  useHasEnabledFeatures,
  useTeamId,
} from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { VRP_TENANT_ID, XIMPLIFI_TENANT_ID } from '@vrplatform/ui-common';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const FeaturesView = lazy(() => import('../../views/features'));
const SettingsView = lazy(() => import('../../views/settings'));

const PartnerDashboardView = lazy(
  () => import('../../views/partner-dashboard')
);
const PartnerAutomationsView = lazy(
  () => import('../../views/partner-automations')
);
const PartnerBillingView = lazy(() => import('../../views/partner-billing'));
const PartnerConnectionsView = lazy(
  () => import('../../views/partner-connections')
);
const PartnerMembersView = lazy(() => import('../../views/partner-members'));
const PartnerOwnerStatementsView = lazy(
  () => import('../../views/partner-statements')
);

const LineMasterListView = lazy(
  () => import('../../views/partner-line-master')
);
const AccountsView = lazy(() => import('../../views/accounts'));
const LegacyReportsView = lazy(() => import('../../views/reports/ReportsView'));

export const PartnerRoutes = () => {
  const hasEnabledFeatures = useHasEnabledFeatures();

  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  const isXimplifiTeam = teamId === XIMPLIFI_TENANT_ID;
  const isVrpTeam = teamId === VRP_TENANT_ID;
  const isPartnerDashboard = dashboard === 'partner';

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        {isXimplifiTeam && isPartnerDashboard && (
          <Route path="line-master-list" element={<LineMasterListView />} />
        )}

        {isVrpTeam && isPartnerDashboard && (
          <Route path="billing">
            <Route index element={<Navigate to="/billing/teams" replace />} />
            <Route path=":tabValue" element={<PartnerBillingView />} />
          </Route>
        )}

        <Route path="dashboard">
          <Route
            index
            element={<Navigate to="/dashboard/overview" replace />}
          />
          <Route path=":tabValue" element={<PartnerDashboardView />} />
        </Route>

        <Route path="members" element={<PartnerMembersView />} />
        {isVrpTeam && isPartnerDashboard && (
          <Route path="accounts">
            <Route
              index
              element={<Navigate to="/accounts/overview" replace />}
            />
            <Route path=":tabValue" element={<AccountsView />} />
          </Route>
        )}
        <Route path="team-statements">
          <Route
            index
            element={
              <Navigate to="/team-statements/owner-statements" replace />
            }
          />
          <Route path=":tabValue" element={<PartnerOwnerStatementsView />} />
        </Route>

        <Route path="team-automations">
          <Route
            index
            element={<Navigate to="/team-automations/overview" replace />}
          />
          <Route path=":tabValue" element={<PartnerAutomationsView />} />
        </Route>

        <Route path="team-connections">
          <Route index element={<PartnerConnectionsView />} />
          <Route path=":tabValue" element={<PartnerConnectionsView />} />
        </Route>

        {hasEnabledFeatures && (
          <>
            <Route path="features">
              <Route path=":tabValue" element={<FeaturesView />} />
              <Route index element={<Navigate to="features" replace />} />
            </Route>
          </>
        )}

        <Route path="reports">
          <Route index element={<LegacyReportsView />} />
          <Route path=":id" element={<LegacyReportsView />} />
        </Route>

        {/* user settings */}
        <Route path="settings/*" element={<SettingsView />} />

        {/* catch all */}
        <Route
          path="*"
          element={<Navigate to="/dashboard/overview" replace />}
        />
      </Routes>
    </Suspense>
  );
};

import { useMutation } from '@finalytic/data';
import { DeleteModal } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { DepositRow } from './useDepositsTableQuery';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  deposit: Maybe<DepositRow>;
}

export const DepositEllipsisMenuModals = ({
  deleteModal,
  deposit: initial,
}: {
  deposit: ModalProps['deposit'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const deposit = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} deposit={deposit} />
    </>
  );
};

const Delete = ({ opened, closeModal, deposit }: ModalProps) => {
  const depositId = deposit?.id;

  const { mutate } = useMutation(
    (q, args: { id: string }) => {
      return q.deleteTransaction({ id: args.id })?.id;
    },
    {
      successMessage: {
        message:
          'You successfully deleted the deposit. If this was done in error, please get in touch with us.',
      },
      invalidateQueryKeys: ['deposits'],
    }
  );

  return (
    <DeleteModal
      opened={opened}
      onClose={closeModal}
      onSubmit={async () => {
        if (!depositId) return;

        await mutate({
          args: {
            id: depositId,
          },
        });
      }}
      title={'Delete this deposit?'}
      subtitle={
        'Are you sure you want to delete this deposit? This action cannot be undone.'
      }
    />
  );
};

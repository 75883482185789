import { Button, InputSelect } from '@finalytic/components';
import { SelectItem, showWarnNotification } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { InputWrapper, Stack, rem } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { InputCurrency } from '../../../components';

type BillingDate = 'current' | 'next' | 'previous';

type FormInputs = {
  startDate: BillingDate;
  // country: string;
  currency: string;
};

export const OnboardingSettingsRoute = () => {
  const goto = useNavigate();
  const methods = useForm<FormInputs>();

  const submit = (data: FormInputs) => {
    if (!data.startDate)
      return showWarnNotification({
        title: 'Choose your start date',
        message: 'Please select a start date to continue your onboarding.',
      });

    alert('//TODO: need to handle currency and billing start date');
    goto('/trial');
  };

  return (
    <>
      <Stack w="100%" mb={rem(80)} gap="xl">
        <Controller
          control={methods.control}
          name="startDate"
          defaultValue="current"
          render={({ field, fieldState }) => {
            const now = day();

            const options: SelectItem<BillingDate>[] = [
              {
                label: `This month (${now.format('LL')})`,
                value: 'current',
              },
              {
                label: `Next month (${now.add(1, 'month').format('LL')})`,
                value: 'next',
              },
              {
                label: `Last month (${now.subtract(1, 'month').format('LL')})`,
                value: 'previous',
              },
            ];

            const value =
              options.find((option) => option.value === field.value) || null;

            return (
              <InputWrapper
                label="When do you want to start?"
                error={fieldState.error?.message}
              >
                <InputSelect
                  value={value}
                  type="single"
                  data={{
                    options,
                  }}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  inputProps={{
                    error: !!fieldState.error,
                  }}
                  dropdownProps={{
                    width: 'target',
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        {/* <Controller
          control={methods.control}
          name="country"
          defaultValue={'US'}
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                label="Which country do you operate in?"
                error={fieldState.error?.message}
              >
                <InputCountry
                  value={field.value || null}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  error={!!fieldState.error?.message}
                />
              </InputWrapper>
            );
          }}
        /> */}

        <Controller
          control={methods.control}
          name="currency"
          defaultValue="usd"
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                label="Currency for accounting records"
                error={fieldState.error?.message}
              >
                <InputCurrency
                  value={(field.value as any) || null}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  error={!!fieldState.error}
                />
              </InputWrapper>
            );
          }}
        />
      </Stack>

      <Button
        variant="primary"
        sx={{
          width: '100%',
        }}
        onClick={methods.handleSubmit(submit)}
      >
        Continue
      </Button>
    </>
  );
};

import {
  Button,
  Input,
  InputAmount,
  InputDay,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  captureSentryError,
  type gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useInvalidateQueries,
  useMe,
  useQuery,
  useTeam,
  useTeamId,
  useTransactionsServiceCreateTransaction,
  useTransactionsServiceUpdateTransaction,
} from '@finalytic/data';
import type { currency_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import {
  Drawer,
  IconButton,
  LoadingIndicator,
  type SelectItem,
  showErrorNotification,
} from '@finalytic/ui';
import { type Maybe, bankersRoundToCents, day, utc } from '@finalytic/utils';
import {
  Box,
  Center,
  Divider,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  formatExpenseLineTotal,
  getListingName,
  whereAccounts,
  whereListings,
  whereReservations,
} from '@vrplatform/ui-common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { InputCurrency } from '../../components';
import { DrawerHeader } from '../../drawers/_components';
import type { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';

type Props = {
  opened: boolean;
  closeDrawer: () => void;
  expense?: Maybe<ExpenseRow>;
  loadingQuery?: boolean;
};

type FormInputs = {
  id: string | undefined;
  date: string;
  creditAccountId: string | null;
  currency: currency_enum;
  description: string;
  lines: {
    id: string | undefined;
    taxRateId: string | null;
    debitAccountId: string | null;
    taxBehavior: gqlV2.taxBehavior_enum | null;
    listingId: string | null;
    reservationId: string | null;
    description: string;
    centTotal: number;
    markupId: string | null;
    markupTaxRateId: string | null;
    markupTaxBehavior: gqlV2.taxBehavior_enum | null;
    markupType: 'rate' | 'cent';
    markup: number | undefined;
  }[];
};

const useExpenseForm = () => useFormContext<FormInputs>();

function useTaxRateQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      if (!args.teamId) return [];

      return q
        .taxRates({
          order_by: [{ taxRate: 'asc' }],
          // where: {
          //   tenantId: { _eq: teamId },
          // },
          distinct_on: ['taxRate'],
        })
        .map<SelectItem>((rate) => ({
          value: rate.id,
          label: `${rate.taxRate * 100}%`,
        }));
    },
    {
      variables: {
        teamId,
      },
    }
  );
}

function useExpenseMutation() {
  const [teamId] = useTeamId();

  const invalidate = useInvalidateQueries(['expenses', 'ownerStatements']);

  const {
    mutateAsync: insert,
    isLoading: loadingInsert,
    error: errorInsert,
  } = useTransactionsServiceCreateTransaction({
    onSettled: invalidate,
  });
  const {
    mutateAsync: update,
    isLoading: loadingUpdate,
    error: errorUpdate,
  } = useTransactionsServiceUpdateTransaction({
    onSettled: () => {
      invalidate();
    },
  });

  const mutate = useCallback(
    async (input: FormInputs) => {
      const requestBody: Parameters<typeof insert>[0]['requestBody'] = {
        accountId: input.creditAccountId!,
        currency: input.currency,
        date: input.date,
        description: input.description,
        type: 'expense',
        lines: input.lines.map((line) => ({
          accountId: line.debitAccountId!,
          amount: line.centTotal * 100,
          description: line.description,
          listingId: line.listingId!,
          reservationId: line.reservationId || undefined,
          taxBehavior: line.taxRateId
            ? line.taxBehavior || undefined
            : undefined,
          taxRateId: line.taxRateId || undefined,
          markup: line.markup
            ? {
                amount:
                  line.markupType === 'cent'
                    ? bankersRoundToCents(line.markup)
                    : undefined,
                markupRate:
                  line.markupType === 'rate'
                    ? Number((line.markup / 100).toFixed(4))
                    : undefined,
                taxBehavior: line.markupTaxRateId
                  ? line.markupTaxBehavior || undefined
                  : undefined,
                taxRateId: line.markupTaxRateId || undefined,
              }
            : undefined,
        })),
      };

      if (input.id) {
        await update({
          id: input.id,
          requestBody,
        });
      } else {
        await insert({
          requestBody,
        });
      }
    },
    [teamId, insert, update]
  );

  const error = errorInsert || errorUpdate;

  useEffect(() => {
    const err = error as any;

    if (err) {
      console.error(err);
      captureSentryError(err);
      showErrorNotification({
        title: "Couldn't save expense",
        message:
          err?.body?.message ||
          err?.message ||
          "We couldn't save the expense. Please try again.",
        autoClose: 5000,
      });
    }
  }, [error]);

  return {
    loading: loadingInsert || loadingUpdate,
    mutate,
  };
}

export const LegacyExpenseEditDrawer = ({
  closeDrawer,
  opened,
  expense,
  loadingQuery,
}: Props) => {
  const taxRateQueryData = useTaxRateQuery();

  const methods = useForm<FormInputs>({
    values: expense
      ? {
          id: expense.id,
          currency: expense.currency || 'USD',
          date: utc(expense.date).yyyymmdd(),
          creditAccountId: expense.bankAccount.id || null,
          description: expense.description || '',
          lines: expense.lines.map((line) => {
            const markupType = [null, undefined].includes(line.markup?.rate)
              ? 'cent'
              : 'rate';

            const getMarkup = () => {
              if (markupType === 'rate') {
                const rate = line.markup?.rate || 0;
                return rate * 100;
              }

              return (line.markup?.centTotal || 0) / 100;
            };

            return {
              id: line.id,
              listingId: line.listing.id,
              reservationId: line.reservation.id,
              centTotal: line.centTotal / 100,
              debitAccountId: line.debitAccountId,
              description: line.description,
              taxRateId: line.taxRateId,
              taxBehavior: line.taxBehavior,
              markupType,
              markup: getMarkup(),
              markupId: line.markup?.id,
              markupTaxRateId: line.markup?.taxRateId || null,
              markupTaxBehavior: line.markup?.taxBehavior || null,
            };
          }),
        }
      : undefined,
  });

  const { mutate } = useExpenseMutation();

  const submit = async (values: FormInputs) => {
    try {
      await mutate(values).then(closeDrawer);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Drawer opened={opened} onClose={closeDrawer} size={800}>
      <DrawerHeader
        closeDrawer={closeDrawer}
        title={`${expense ? 'Update' : 'Add'} Expense`}
      />

      {loadingQuery ? (
        <Center>
          <LoadingIndicator />
        </Center>
      ) : (
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={methods.handleSubmit(submit)}
            onReset={() => {
              methods.reset();
              closeDrawer();
            }}
            sx={() => ({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              position: 'relative',
            })}
          >
            <Stack>
              <Day />
              <Description />
              <Currency />
              <ExpenseAccount />
              <Divider mt="sm" />
              <Lines taxRates={taxRateQueryData.data || []} />
            </Stack>
            <Group
              mt="md"
              sx={(theme) => ({
                position: 'sticky',
                top: 'auto',
                bottom: 0,
                height: 60,
                left: 0,
                right: 0,
                zIndex: 10,
                paddingBottom: theme.spacing.md,
                backgroundColor: theme.white,
                borderTop: `1px solid ${theme.colors.gray[2]}`,
                paddingTop: theme.spacing.sm,
                paddingInline: theme.spacing.xs,
                marginRight: `-${theme.spacing.xs}`,
                marginLeft: `-${theme.spacing.xs}`,
              })}
            >
              <Button type="reset" disabled={methods.formState.isSubmitting}>
                Cancel
              </Button>
              <Button
                disabled={!methods.formState.isDirty}
                loading={methods.formState.isSubmitting}
                sx={{ flexGrow: '1!important' as any }}
                type="submit"
                variant="primary"
              >
                {expense ? 'Save changes' : 'Add expense'}
              </Button>
            </Group>
            <LoadingOverlay visible={taxRateQueryData.isLoading} />
          </Box>
        </FormProvider>
      )}
    </Drawer>
  );
};

const Day = () => {
  const methods = useExpenseForm();

  return (
    <Controller
      control={methods.control}
      name="date"
      rules={{
        required: 'Date is required',
      }}
      render={({ field: { value, ...field }, fieldState }) => {
        const v = value ? day(value).toDate() : null;

        return (
          <InputWrapper required label="Date">
            <InputDay
              {...field}
              required
              value={v}
              error={fieldState.error?.message}
              placeholder={day().format('MMMM DD, YYYY')}
              clearable
              popoverProps={{
                position: 'bottom-end',
                withinPortal: true,
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const Description = ({ index }: { index?: number }) => {
  const methods = useExpenseForm();

  const isDefaultDescription = index === undefined;

  return (
    <Box flex={3}>
      <Controller
        control={methods.control}
        name={
          isDefaultDescription ? 'description' : `lines.${index}.description`
        }
        rules={{
          validate: (value) =>
            value?.trim().length > 0 || 'Description is required',
        }}
        render={({ field, fieldState }) => {
          return (
            <InputWrapper
              required
              label={isDefaultDescription ? 'Description' : 'Line description'}
              error={fieldState.error?.message}
            >
              <Input
                {...field}
                error={!!fieldState.error}
                placeholder={'Pool cleaning service'}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const Markup = ({ index }: { index: number }) => {
  const methods = useExpenseForm();

  const type = methods.watch(`lines.${index}.markupType`);

  const isRate = type === 'rate';

  return (
    <Box flex={1}>
      <Controller
        control={methods.control}
        name={`lines.${index}.markup`}
        render={({ field, fieldState }) => {
          return (
            <InputWrapper label="Markup" error={fieldState.error?.message}>
              <InputAmount
                value={field.value}
                setValue={(v) => field.onChange(v)}
                error={!!fieldState.error}
                placeholder={isRate ? '0%' : '$0.00'}
                decimalScale={2}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const CentTotal = ({ index }: { index: number }) => {
  const methods = useExpenseForm();

  return (
    <Box flex={1}>
      <Controller
        control={methods.control}
        name={`lines.${index}.centTotal`}
        rules={{
          validate: (value) => {
            if (typeof value === 'number') return true;

            return 'Amount is required';
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <InputWrapper
              required
              label="Expense Amount"
              error={fieldState.error?.message}
            >
              <InputAmount
                value={field.value}
                setValue={(v) => field.onChange(v)}
                error={!!fieldState.error}
                placeholder={'$0.00'}
                // type="number"
                decimalScale={2}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const MarkupType = ({ index }: { index: number }) => {
  const methods = useExpenseForm();

  return (
    <Box
      maw={130}
      sx={{
        alignSelf: 'flex-start',
        flex: 0,
        paddingTop: 22,
      }}
    >
      <Controller
        control={methods.control}
        defaultValue="rate"
        name={`lines.${index}.markupType`}
        render={({ field }) => {
          return (
            <SegmentedControl
              radius="md"
              data={[
                { label: '$', value: 'cent' },
                { label: '%', value: 'rate' },
              ]}
              {...field}
            />
          );
        }}
      />
    </Box>
  );
};

const Currency = () => {
  const methods = useExpenseForm();

  return (
    <Box>
      <Controller
        control={methods.control}
        name="currency"
        rules={{
          required: 'Currency is required',
        }}
        defaultValue="usd"
        render={({ field, fieldState }) => {
          return (
            <InputWrapper
              required
              label="Currency"
              error={fieldState.error?.message}
            >
              <InputCurrency
                value={field.value}
                setValue={(v) => v?.value && field.onChange(v?.value)}
                error={!!fieldState.error}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const Lines = ({ taxRates }: { taxRates: SelectItem[] }) => {
  const { primaryColor } = useMantineTheme();

  const { fields, append, remove } = useFieldArray<FormInputs>({
    name: 'lines',
    rules: {
      minLength: 1,
      required: true,
    },
  });

  const addLine = () =>
    append({
      markup: undefined,
      markupType: 'rate',
      centTotal: 0,
      description: '',
      id: undefined,
      listingId: null,
      reservationId: null,
      taxRateId: null,
      taxBehavior: null,
      markupId: null,
      markupTaxRateId: null,
      markupTaxBehavior: null,
      debitAccountId: null,
    });

  return (
    <Stack>
      {fields.map((line, index) => {
        return (
          <Box
            key={line.id}
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
            }}
          >
            <Box px="sm" mt={28}>
              <Text>{index + 1}.</Text>
            </Box>
            <Stack key={line.id} gap="xs" sx={{ flex: 1 }}>
              <Group
                align="flex-start"
                sx={{
                  '> *': {
                    flex: 1,
                  },
                }}
              >
                <Description index={index} />
                <LineAccount index={index} />
              </Group>

              <Group
                align="flex-start"
                sx={{
                  '> *': {
                    flex: 1,
                  },
                }}
              >
                <Listing index={index} />
                <Reservation index={index} />
              </Group>

              <Group align="flex-start">
                <CentTotal index={index} />
                <TaxRate taxRates={taxRates} index={index} type="taxRateId" />
                <TaxBehavior index={index} type="taxBehavior" />
              </Group>

              <Group align="flex-start" wrap="nowrap">
                <MarkupType index={index} />
                <Markup index={index} />
                <TaxRate
                  taxRates={taxRates}
                  index={index}
                  type="markupTaxRateId"
                />
                <TaxBehavior index={index} type="markupTaxBehavior" />
              </Group>
              <Divider />
              <LineTotalDisplay index={index} taxRates={taxRates} />
            </Stack>

            <Box px="sm" mt={28}>
              <IconButton onClick={() => remove(index)}>
                <Icon
                  icon="TrashIcon"
                  size={20}
                  color={({ colors }) => colors.red[6]}
                />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <Button
        variant="light"
        color={primaryColor}
        leftIcon={'PlusIcon'}
        sx={{
          alignSelf: 'flex-end',
        }}
        type="button"
        onClick={addLine}
      >
        Add expense line
      </Button>
    </Stack>
  );
};

const LineTotalDisplay = ({
  index,
  taxRates,
}: { index: number; taxRates: SelectItem[] }) => {
  const { watch } = useExpenseForm();

  const currency = watch('currency') || 'usd';
  const centTotal = watch(`lines.${index}.centTotal`) || 0;
  const markup = watch(`lines.${index}.markup`) || 0;
  const markupType = watch(`lines.${index}.markupType`) || 'rate';
  const taxRateId = watch(`lines.${index}.taxRateId`);
  const taxBehavior = watch(`lines.${index}.taxBehavior`) || 'included';
  const markupTaxRateId = watch(`lines.${index}.markupTaxRateId`);
  const markupTaxBehavior =
    watch(`lines.${index}.markupTaxBehavior`) || 'included';

  const formattedLineTotal = useMemo(() => {
    const taxRate = taxRates.find((rate) => rate.value === taxRateId)?.label;
    const markupTaxRate = taxRates.find(
      (rate) => rate.value === markupTaxRateId
    )?.label;

    return formatExpenseLineTotal({
      centTotal,
      currency,
      markup,
      markupType,
      markupTaxRate,
      markupTaxBehavior,
      taxRate,
      taxBehavior,
    });
  }, [
    centTotal,
    markup,
    markupType,
    taxRates,
    taxBehavior,
    markupTaxBehavior,
    taxRateId,
    markupTaxRateId,
  ]);

  return (
    <Box flex={1.5}>
      <InputWrapper label="Total:">
        <Input disabled value={formattedLineTotal} />
      </InputWrapper>
    </Box>
  );
};

function useAccountQuery(type: 'expense' | 'line', search: string) {
  const [{ id: teamId }] = useTeam();

  return useInfiniteQuery(
    (q, { teamId, search, type }, { limit, offset }) => {
      const where: gqlV2.account_bool_exp = whereAccounts({
        tenantId: teamId,
        search,
        type: type === 'expense' ? 'bank' : undefined,
        classification: type === 'line' ? 'expense' : undefined,
      });

      const list = q
        .accounts({
          where,
          order_by: [{ title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.title || 'No name',
        }));

      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
        type,
      },
    }
  );
}

const ExpenseAccount = () => {
  const methods = useExpenseForm();
  const [search, setSearch] = useState('');

  const queryData = useAccountQuery('expense', search);

  return (
    <Box>
      <Controller
        control={methods.control}
        name={'creditAccountId'}
        rules={{
          required: 'Account is required',
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .accounts({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: item.title || 'No name',
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const account = field.value ? data || null : null;

          return (
            <InputWrapper
              label="Payment Account"
              error={fieldState.error?.message}
              required
            >
              <InputSelect
                type="single"
                value={account}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select Account',
                  loadingQuery: loading,
                  withClearButton: true,
                }}
                dropdownProps={{
                  width: 'target',
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const LineAccount = ({ index }: { index: number }) => {
  const methods = useExpenseForm();
  const [search, setSearch] = useState('');

  const queryData = useAccountQuery('line', search);

  return (
    <Box maw={250}>
      <Controller
        control={methods.control}
        name={`lines.${index}.debitAccountId`}
        rules={{
          required: 'Account is required',
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .accounts({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: item.title || 'No name',
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const account = field.value ? data || null : null;

          return (
            <InputWrapper
              label="Expense Account"
              error={fieldState.error?.message}
              required
            >
              <InputSelect
                type="single"
                value={account}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select Account',
                  loadingQuery: loading,
                  withClearButton: true,
                }}
                dropdownProps={{
                  width: 'target',
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const Listing = ({ index }: { index: number }) => {
  const methods = useExpenseForm();
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        GL,
        meId,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  return (
    <Box>
      <Controller
        control={methods.control}
        name={`lines.${index}.listingId`}
        rules={{
          required: 'Listing is required',
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .listings({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: getListingName(item),
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const listing = field.value ? data || null : null;

          return (
            <InputWrapper
              label="Listing"
              error={fieldState.error?.message}
              required
            >
              <InputSelect
                type="single"
                value={listing}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select listing',
                  loadingQuery: loading,
                  withClearButton: true,
                }}
                dropdownProps={{
                  width: 'target',
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const getReservation = (res: gqlV2.reservation) => ({
  value: res.id,
  label: [res.guestName, res.confirmationCode].filter(Boolean).join(' - '),
  description: `${utc(res?.checkIn).format('D. MMM')} -
  ${utc(res?.checkOut).format('D. MMM. YYYY')}`,
});

const Reservation = ({ index }: { index: number }) => {
  const methods = useExpenseForm();
  const { id: meId } = useMe();
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId }, { limit, offset }) => {
      const where: gqlV2.reservation_bool_exp = whereReservations({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        includeLines: false,
        meId,
      });

      const list = q
        .reservations({
          where,
          order_by: [{ checkIn: 'desc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>(getReservation);

      const aggregate =
        q.reservationAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
      },
    }
  );

  return (
    <Box>
      <Controller
        control={methods.control}
        name={`lines.${index}.reservationId`}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (!args.value) return null;

              return q
                .reservations({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>(getReservation)[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const reservation = data || null;

          return (
            <InputWrapper label="Reservation" error={fieldState.error?.message}>
              <InputSelect
                type="single"
                value={reservation}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select reservation',
                  loadingQuery: loading,
                  withClearButton: true,
                }}
                dropdownProps={{
                  width: 'target',
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};

const TaxRate = ({
  index,
  type,
  taxRates,
}: {
  index: number;
  type: 'taxRateId' | 'markupTaxRateId';
  taxRates: SelectItem[];
}) => {
  const methods = useExpenseForm();

  return (
    <Controller
      control={methods.control}
      name={`lines.${index}.${type}`}
      render={({ field, fieldState }) => {
        const taxRate =
          taxRates.find((rate) => rate.value === field.value) || null;

        return (
          <InputWrapper label="Tax rate" error={fieldState.error?.message}>
            <InputSelect
              type="single"
              value={taxRate}
              setValue={(v) => field.onChange(v?.value || null)}
              data={{
                options: taxRates,
              }}
              inputProps={{
                error: !!fieldState.error,
                placeholder: 'Tax rate',
                withClearButton: true,
                width: 130,
              }}
              dropdownProps={{
                width: 'target',
                hideSearch: true,
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const TaxBehavior = ({
  index,
  type,
}: { index: number; type: 'taxBehavior' | 'markupTaxBehavior' }) => {
  const methods = useExpenseForm();

  const taxRate = methods.watch(
    `lines.${index}.${type === 'taxBehavior' ? 'taxRateId' : 'markupTaxRateId'}`
  );

  const options = useMemo<SelectItem<gqlV2.taxBehavior_enum>[]>(
    () => [
      {
        label: 'Inclusive',
        value: 'included',
      },
      {
        label: 'Exclusive',
        value: 'excluded',
      },
    ],
    []
  );

  return (
    <Controller
      control={methods.control}
      name={`lines.${index}.${type}`}
      defaultValue="included"
      render={({ field, fieldState }) => {
        const initial =
          options.find((rate) => rate.value === field.value) || null;

        const taxBehavior = !taxRate ? null : initial;

        return (
          <InputWrapper label="Tax behaviour" error={fieldState.error?.message}>
            <InputSelect
              type="single"
              value={taxBehavior}
              setValue={(v) => v?.value && field.onChange(v?.value)}
              data={{
                options,
              }}
              inputProps={{
                error: !!fieldState.error,
                placeholder: initial?.label || 'Included',
                withClearButton: false,
                disabled: !taxRate,
                width: 130,
              }}
              dropdownProps={{
                width: 'target',
                hideSearch: true,
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

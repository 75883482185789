import { Button, Collapse } from '@finalytic/components';
import { useInvalidateQueries } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  ArrowRightIcon,
  Icon,
  Loader2Icon,
  LoaderIcon,
  LogIn2Icon,
  LogOut2Icon,
  RefreshCwIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable } from '@finalytic/table';
import { IconButton, LoadingIndicator, Logo, useAppName } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { DrawerHeader, DrawerInfoCard } from '../_components';
import {
  AutomationHistoryRerunButtons,
  AutomationHistoryStatusBadge,
  SyncedFromTable,
  SyncedTablePlaceholder,
  SyncedToTable,
} from './_components';
import { useSyncedFromToTableColumns } from './_hooks';
import {
  Workflow,
  useAutomationHistoryDrawerSubscription,
} from './useAutomationHistoryDrawerSubscription';

export const SingleWorkflow = ({
  workflowId,
  closeDrawer,
  hasMultiple,
  resetActiveWorkflowId,
}: {
  workflowId: string;
  closeDrawer: () => void;
  hasMultiple: boolean;
  resetActiveWorkflowId: () => void;
}) => {
  const { data: workflows, isLoading } = useAutomationHistoryDrawerSubscription(
    [workflowId]
  );
  const invalidate = useInvalidateQueries(['actions', 'tasks', 'jobPlans']);

  const [tab, setTab] = useState('overview');

  const workflow = workflows?.[0];

  const title = useMemo<string>(() => {
    if (workflow?.isExtractWorkflow) return 'Fetching data';

    return workflow?.automation.name || '';
  }, [workflow]);

  return (
    <Box
      px="sm"
      pb="sm"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Group>
        <DrawerHeader
          closeDrawer={closeDrawer}
          title={
            <>
              <Group>
                <Title order={3} display="block">
                  {title}
                </Title>
                {['started', 'queued'].includes(workflow?.status || '') && (
                  <AutomationHistoryStatusBadge status={workflow?.status} />
                )}
              </Group>
            </>
          }
          loading={isLoading}
          type="Sync History"
          onReturnClick={
            hasMultiple ? () => resetActiveWorkflowId() : undefined
          }
        >
          {workflow?.hypervisorRef === 'trigger' && (
            <HiddenFeatureIndicator
              permission="super-admin"
              p={5}
              customTooltip="Trigger workflow"
            >
              <Icon
                icon="RocketIcon"
                size={16}
                sx={{
                  display: 'inline',
                }}
              />
            </HiddenFeatureIndicator>
          )}
          <Button
            onClick={() => invalidate()}
            variant="light"
            leftIcon={RefreshCwIcon}
          >
            Refresh data
          </Button>
          <AutomationHistoryRerunButtons workflowId={workflowId} />
        </DrawerHeader>
      </Group>
      {isLoading ? (
        <LoadingIndicator size="md" isFullPageLoading />
      ) : !workflowId || !workflow ? (
        <Center mih="7vh">
          <h1>History not found</h1>
        </Center>
      ) : (
        <Content
          workflowId={workflowId}
          workflow={workflow}
          setTab={setTab}
          tab={tab}
        />
      )}
    </Box>
  );
};

type ContentProps = {
  workflowId: string;
  workflow: Workflow;
  tab: string;
  setTab: (tab: string) => void;
};

const Content = ({ workflow, workflowId, setTab, tab }: ContentProps) => {
  const { errors, syncFrom, syncTo } = useMemo(() => {
    return {
      errors: workflow.errors.aggregate,
      syncTo: workflow.aggregateSyncedTo,
      syncFrom: workflow.aggregateSyncedFrom,
    };
  }, [
    workflow.errors.aggregate,
    workflow.aggregateSyncedTo,
    workflow.aggregateSyncedFrom,
  ]);

  const isLegacy = workflow.hypervisorRef === 'legacy';

  const goToErrors = () => setTab('errors');

  const isWorkflowPending = ['started', 'queued', 'pending'].includes(
    workflow.status || ''
  );

  return (
    <Tabs
      value={tab}
      onChange={(tab) => tab && setTab(tab)}
      px="sm"
      keepMounted={false}
      styles={(theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
        panel: {
          height: '100%',
          flex: 1,
          marginTop: theme.spacing.lg,
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      })}
    >
      <Tabs.List>
        <Tabs.Tab value="overview">Overview</Tabs.Tab>
        {isLegacy ? null : (
          <Tabs.Tab value="sync-from">Synced from ({syncFrom})</Tabs.Tab>
        )}
        {isLegacy ? null : (
          <Tabs.Tab value="sync-to">Synced to ({syncTo})</Tabs.Tab>
        )}
        {!!errors && <Tabs.Tab value="errors">Errors ({errors})</Tabs.Tab>}
      </Tabs.List>

      <Tabs.Panel value="overview">
        <Overview workflow={workflow} goToErrors={goToErrors} />
      </Tabs.Panel>
      <Tabs.Panel value="sync-from">
        <SyncedFromTable
          workflowId={workflowId}
          isWorkflowPending={isWorkflowPending}
        />
      </Tabs.Panel>
      <Tabs.Panel value="sync-to">
        <SyncedToTable
          workflowId={workflowId}
          type="success"
          isWorkflowPending={isWorkflowPending}
        />
      </Tabs.Panel>
      <Tabs.Panel value="errors">
        <SyncedToTable
          workflowId={workflowId}
          type="failed"
          isWorkflowPending={isWorkflowPending}
        />
      </Tabs.Panel>
    </Tabs>
  );
};

const Overview = ({
  workflow,
  goToErrors,
}: { workflow: Workflow; goToErrors: () => void }) => {
  if (!workflow) return null;

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <AutomationHistoryStatusBadge status={workflow.status} />,
          },
          {
            icon: Loader2Icon,
            title: 'Current step',
            text:
              workflow.status === 'started' &&
              workflow.isExtractWorkflow &&
              workflow?.title ? (
                <Text>{workflow.title}</Text>
              ) : null,
          },
          {
            icon: UserIcon,
            title: 'Triggered by',
            text: workflow.triggeredBy,
          },
          {
            icon: LogIn2Icon,
            title: 'Synced from',
            text: (
              <AppIcon
                {...(workflow.isExtractWorkflow
                  ? workflow.extractConnection
                  : workflow.leftConnection)}
              />
            ),
          },
          {
            icon: LogOut2Icon,
            title: 'Synced to',
            text: (
              <AppIcon
                {...(workflow.isExtractWorkflow
                  ? {
                      id: 'finalytic',
                      icon: undefined,
                      name: '',
                    }
                  : workflow.rightConnection)}
              />
            ),
          },
        ]}
      />
      <OverviewErrorTable
        errors={workflow.errors}
        status={workflow.status}
        goToErrors={goToErrors}
      />
    </>
  );
};

const OverviewErrorTable = ({
  errors,
  goToErrors,
  status,
}: {
  errors: Workflow['errors'];
  status: Workflow['status'];
  goToErrors?: () => void;
}) => {
  const { primaryColor } = useMantineTheme();

  const columns = useSyncedFromToTableColumns();

  const isShowMoreErrors = errors.aggregate > errors.rowData.length;

  return (
    <Box mt="lg">
      <Collapse
        title={
          <Text component="span" size="sm">
            Errors
            <Text color="gray" ml="xs" component="span">
              {errors.aggregate}
            </Text>
          </Text>
        }
        rightSection={
          !!errors.aggregate &&
          goToErrors && (
            <IconButton onClick={goToErrors}>
              <ArrowRightIcon size={18} />
            </IconButton>
          )
        }
        minHeight={30}
        defaultOpened
      >
        {!errors.rowData.length ? (
          <SyncedTablePlaceholder status={status} />
        ) : (
          <>
            <LazyTable
              table={{
                columns,
                hideHeader: true,
                hideTopBar: true,
              }}
              data={{
                rowCount: errors.aggregate,
                rows: errors.rowData,
                error: null,
                loading: false,
              }}
            />
            {isShowMoreErrors &&
              (!goToErrors ? (
                <Text size="xs" color="gray" ta="center" pt="md">
                  {errors.aggregate - errors.rowData.length} additional errors
                </Text>
              ) : (
                <Button
                  onClick={goToErrors}
                  color={primaryColor}
                  variant="light"
                  sx={(theme) => ({
                    marginInline: 'auto',
                    display: 'block',
                    marginTop: theme.spacing.sm,
                  })}
                  rightIcon={ArrowRightIcon}
                >
                  Show more
                </Button>
              ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};

const AppIcon = ({
  icon,
  id,
  name,
}: { icon: Maybe<string>; id: Maybe<string>; name: Maybe<string> }) => {
  const { appName } = useAppName();

  const isFinalyticConnection = id === 'finalytic';

  if (!id) return null;

  return (
    <Group gap="xs">
      <Avatar size="sm" radius="xl">
        <Logo
          img={isFinalyticConnection ? undefined : icon}
          width={isFinalyticConnection ? 24 : 22}
        />
      </Avatar>
      <Text component="span">{isFinalyticConnection ? appName : name}</Text>
    </Group>
  );
};

import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon } from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { useNavigate } from 'react-router';
import { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';

export const ExpenseEllipsisMenuItems = ({
  expense,
  openDeleteModal,
  openEditLegacyDrawer,
}: {
  expense: ExpenseRow;
  openDeleteModal: () => void;
  openEditLegacyDrawer: () => void;
}) => {
  const goto = useNavigate();

  return (
    <>
      <EllipsisMenuItem
        onClick={() => goto(`/expenses/${expense.id}/edit`)}
        customIcon={<Edit3Icon size={16} />}
      >
        Edit
      </EllipsisMenuItem>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuItem
          onClick={openEditLegacyDrawer}
          customIcon={<Edit3Icon size={16} />}
        >
          Edit (Legacy Drawer)
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={openDeleteModal}>
        Delete
      </EllipsisMenuDangerItem>
      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuItem
          customIcon={
            <Icon
              icon="CopyIcon"
              size={16}
              color={(theme) => theme.colors.neutral[5]}
            />
          }
          disabled={!expense.id}
          onClick={() => {
            navigator.clipboard.writeText(`${expense.id}`).then(() =>
              showSuccessNotification({
                message: 'Expense ID was added to your clipboard.',
              })
            );
          }}
        >
          Copy ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

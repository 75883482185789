import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  ArrayParam,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { getListingName, whereListings } from '@vrplatform/ui-common';
import { useState } from 'react';

export const useDepositsTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingIds: ArrayParam,
    date: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        type: undefined,
        listingIds: undefined,
        date: undefined,
      };

      setFilter(initial);
    },
  };
};

export const DepositsTableFilter = () => {
  const { filter, setFilter } = useDepositsTableFilter();

  return (
    <Group>
      <Filter.Search
        value={filter.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      <DateFilter />
      <ListingFilter />
    </Group>
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useDepositsTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const ListingFilter = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = useDepositsTableFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: filter.listingIds || [],
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

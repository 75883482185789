import { useInfiniteQuery } from '@finalytic/data';
import { order_by, transaction } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum } from '@finalytic/utils';
import { getListingName } from '@vrplatform/ui-common';
import { generalLedgerSorting } from '../../../general-ledger/useGeneralLedgerDetailTableQuery';
import { useWhereDeposits } from './useWhereDeposits';

type Params = {
  sorting: MRT_SortingState;
};

export type DepositRow = NonNullable<
  ReturnType<typeof useDepositsTableQuery>['data']
>['pages'][number]['list'][number];

export const getDeposit = (
  deposit: transaction,
  opts?: { includeJournalEntries: boolean }
) => {
  const lines =
    deposit
      .lines({
        order_by: [{ createdAt: 'asc_nulls_last' }],
      })
      .map((line) => {
        const additions = line
          .additions({
            order_by: [{ createdAt: 'asc_nulls_last' }],
          })
          .map((addition) => ({
            id: addition.id,
            assignment: addition.accountAssignmentType,
            centTotal: addition.centTotal,
          }));

        return {
          id: line.id,
          type: line.type,
          debitAccountId: line.debitAccountId,
          accountAssignmentType: line.accountAssignmentType,
          centTotal: line.centTotal || 0,
          merchantFeeCentTotal:
            additions.find((x) => x.assignment === 'deposit_merchantFee')
              ?.centTotal || 0,
          channelFeeCentTotal:
            additions.find((x) => x.assignment === 'deposit_channelFee')
              ?.centTotal || 0,
          description: line.description || '',
          // taxRateId: line.taxRateId || null,
          // taxBehavior: line.taxBehavior || null,
          listing: {
            id: line.listing?.id,
            name: getListingName(line.listing) || 'No name',
          },
          reservation: {
            id: line.reservation?.id,
            confirmationCode: line.reservation?.confirmationCode,
            guestName: line.reservation?.guestName,
            checkIn: line.reservation?.checkIn,
            checkOut: line.reservation?.checkOut,
          },
        };
      }) || [];

  return {
    id: deposit.id as string,
    date: deposit.date,
    uniqueRef: deposit.uniqueRef,
    status:
      deposit.status === 'active' ? ('Paid' as const) : ('Unpaid' as const),
    bankAccount: {
      id: deposit.creditAccountId,
      title: deposit.account?.title,
    },
    connection: {
      id: deposit.connectionId,
      name: deposit.connection?.name,
      logo: deposit.connection?.app?.iconRound,
    },
    description: deposit.description,
    centTotal: sum(
      lines.map(
        (line) =>
          line.centTotal - line.merchantFeeCentTotal - line.channelFeeCentTotal
      )
    ),
    currency: deposit.currency!,
    lines,
    journalEntries: opts?.includeJournalEntries
      ? deposit
          .journalEntries({
            where: {
              status: {
                _neq: 'archived',
              },
            },
            order_by: generalLedgerSorting,
          })
          .map((je) => ({
            id: je.id,
            txnAt: je.txnAt,
            txnNum: je.txnNum,
            description: je.description!,
            centTotal: je.centTotal!,
            currency: je.currency,
            account: je.account?.title,
          }))
      : [],
  };
};

export const useDepositsTableQuery = ({ sorting }: Params) => {
  const where = useWhereDeposits();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => {
        const sortId = sort.id as 'date' | 'description';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((deposit) => getDeposit(deposit));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'deposits',
      variables: {
        where,
        sorting,
      },
    }
  );
};

import { useInfiniteQuery } from '@finalytic/data';
import { order_by, taxBehavior_enum, transaction } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum } from '@finalytic/utils';
import { getListingName } from '@vrplatform/ui-common';
import { generalLedgerSorting } from '../../general-ledger/useGeneralLedgerDetailTableQuery';
import { useWhereExpenses } from './useWhereExpenses';

type Params = {
  sorting: MRT_SortingState;
};

export type ExpenseRow = NonNullable<
  ReturnType<typeof useExpenseTableQuery>['data']
>['pages'][number]['list'][number];

type Markup = {
  id: any;
  centTotal: number | undefined;
  rate: any;
  taxRateId: any;
  taxBehavior: taxBehavior_enum | null;
};

export const getExpense = (
  expense: transaction,
  opts?: {
    includeJournalEntries: boolean;
  }
) => {
  const lines = expense
    .lines({
      order_by: [{ createdAt: 'asc_nulls_last' }],
    })
    .map((line) => ({
      id: line.id,
      debitAccountId: line.debitAccountId,
      centTotal: line.centTotal ?? 0,
      description: line.description || '',
      taxRateId: line.taxRateId || null,
      taxBehavior: line.taxBehavior || null,
      markup: line
        .additions({
          where: {
            accountAssignmentType: { _eq: 'expense_markup' },
          },
          order_by: [{ updatedAt: 'desc_nulls_last' }],
          limit: 1,
        })
        .map<Markup | undefined>((markup) => ({
          id: markup.id,
          centTotal: markup.centTotal,
          rate: null,
          taxRateId: null,
          taxBehavior: null,
        }))[0],
      listing: {
        id: line.listing?.id,
        name: getListingName(line.listing) || 'No name',
      },
      reservation: {
        id: line.reservation?.id,
        confirmationCode: line.reservation?.confirmationCode,
        guestName: line.reservation?.guestName,
        guests: line.reservation?.guests,
        checkIn: line.reservation?.checkIn,
        checkOut: line.reservation?.checkOut,
      },
    }));

  return {
    id: expense.id,
    date: expense.date,
    paidStatus: expense.paidStatus,
    bankAccount: {
      id: expense.creditAccountId,
      title: expense.account?.title,
    },
    description: expense.description,
    type: expense.type,
    uniqueRef: expense.uniqueRef,
    centTotal: sum(
      lines.map((line) => line.centTotal + (line.markup?.centTotal || 0))
    ),
    currency: expense.currency!,
    lines,
    connection: {
      id: expense.connectionId,
      name: expense.connection?.name,
      logo: expense.connection?.app?.iconRound,
    },
    journalEntries: opts?.includeJournalEntries
      ? expense
          .journalEntries({
            where: {
              status: {
                _neq: 'archived',
              },
            },
            order_by: generalLedgerSorting,
          })
          .map((je) => ({
            id: je.id,
            txnAt: je.txnAt,
            txnNum: je.txnNum,
            description: je.description!,
            centTotal: je.centTotal!,
            currency: je.currency,
            account: je.account?.title,
          }))
      : [],
  };
};

export const useExpenseTableQuery = ({ sorting }: Params) => {
  const where = useWhereExpenses();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => {
        const sortId = sort.id as 'date' | 'description';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((expense) => getExpense(expense));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'expenses',
      variables: {
        where,
        sorting,
      },
    }
  );
};

import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon } from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { useNavigate } from 'react-router';
import { DepositRow } from './useDepositsTableQuery';

export const DepositEllipsisMenuItems = ({
  deposit,
  openDeleteModal,
}: {
  deposit: DepositRow;
  openDeleteModal: () => void;
}) => {
  const goto = useNavigate();

  return (
    <>
      <>
        <EllipsisMenuItem
          onClick={() => goto(`/reconciliation/deposits/${deposit.id}/edit`)}
          customIcon={<Edit3Icon size={16} />}
        >
          Edit
        </EllipsisMenuItem>

        <EllipsisMenuDivider />

        <EllipsisMenuDangerItem onClick={openDeleteModal}>
          Delete
        </EllipsisMenuDangerItem>
      </>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuItem
          customIcon={
            <Icon
              icon="CopyIcon"
              size={16}
              color={(theme) => theme.colors.neutral[5]}
            />
          }
          disabled={!deposit.id}
          onClick={() => {
            navigator.clipboard.writeText(`${deposit.id}`).then(() =>
              showSuccessNotification({
                message: 'Deposit ID was added to your clipboard.',
              })
            );
          }}
        >
          Copy ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

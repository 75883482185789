import { useQuery, useTeamId } from '@finalytic/data';
import { SelectItem } from '@finalytic/ui';

export function useTaxRatesQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return q
        .rates({
          order_by: [
            {
              name: 'asc',
            },
          ],
          where: {
            tenantId: { _eq: args.teamId },
          },
        })
        .map<SelectItem & { percentage: number }>((rate) => {
          return {
            label: rate.name || 'No name',
            value: rate.id,
            percentage: rate.percentage ?? 0,
          };
        });
    },
    {
      keepPreviousData: true,
      variables: {
        teamId,
      },
      queryKey: ['taxRates'],
    }
  );
}

import {
  useAccountingConnection,
  useDashboard,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { getAutomationInput, useRunDrawer } from './useRunDrawer';

type AutomationInputType =
  | 'finalytic.reservation'
  | 'finalytic.payment'
  | 'finalytic.listing'
  | 'finalytic.ownerStatement';

export const useAutomationModal = (type: AutomationInputType) => {
  const [{ automations }] = useTeam();
  const { isPartnerAdmin } = useTeamRole();
  const { postAutomations } = useRunDrawer();
  const { accounting, accountingPlatforms } = useAccountingConnection();

  const [opened, handlers] = useDisclosure(false);

  const [dashboard] = useDashboard();

  return useMemo(() => {
    const filteredAutomations = (automations || []).filter((aut) => {
      const isAutomationOfCorrectType =
        getAutomationInput(aut?.template).schema === type;

      const isPublicAutomation =
        aut?.template.visibility?.toLowerCase() === 'public';

      let isAccounting = true;

      const hasAccountingApp = accountingPlatforms.some((x) =>
        Object.keys(aut.connections).includes(x.appId || '')
      );

      if (hasAccountingApp) {
        isAccounting = [aut.leftConnectionId, aut.rightConnectionId].some(
          (x) => x === accounting?.id
        );
      }

      const isActive = aut.status !== 'archived';

      return (
        isAutomationOfCorrectType &&
        isPublicAutomation &&
        isAccounting &&
        isActive
      );
    });

    return {
      hasAutomations: dashboard !== 'owner' && filteredAutomations.length > 0,
      automations: filteredAutomations,
      postAutomations: async (params: Parameters<typeof postAutomations>[0]) =>
        postAutomations(params).then(handlers.close),
      opened,
      handlers,
    };
  }, [
    automations,
    dashboard,
    isPartnerAdmin,
    postAutomations,
    handlers,
    opened,
    accounting?.id,
    accountingPlatforms,
  ]);
};

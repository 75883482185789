export const VRP_TENANT_ID = '7bfec41f-aa3f-4602-95d2-f7996e4f0e59';

export const DEMO_TEST_PARTNER_TENANT_ID =
  '0b6a7bd6-56cc-46b5-b2ab-1bd7d1e994fa';

export const XIMPLIFI_TENANT_ID = 'd6ab2bbe-e0fc-48d9-89a3-c3c05f4e79b5';

export const VRP_PLATFORM = {
  name: 'VRPlatform',
  from: 'notifications@vrplatform.app',
  portal: 'https://portal.vrplatform.app',
  logo: 'https://images.clerk.dev/uploaded/img_2HU8qby65rUiwkf3k2bC0XnUxVz.png',
  tenantId: VRP_TENANT_ID,
} as const;

// Hyperline coupons
export const XIMPLIFI_COUPON_ID = 'cou_MXCaik3r19_Xkn';
export const ACCOUNTING_PARTNER_COUPON_ID = 'cou_PJQ328MBAD9kxY';
export const HYPERLINE_DEFAULT_PLAN_ID = 'plan_Dyb5P0ICZ6HsIT';
export const DEV_HYPERLINE_DEFAULT_PLAN_ID = 'plan_UTCVXFQnKD0oMS';

import { useQuery } from '@finalytic/data';
import { AutomationIcon } from '@finalytic/ui';
import { type Maybe, toTitleCase } from '@finalytic/utils';
import { Avatar, Tooltip, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

type Props = {
  sourceId: string;
  id: string;
};

type Action = {
  id: string;
  status: string;
  message: string;
  type: string;
  automationTemplateType: string;
  automationTitle?: Maybe<string>;
};

export function PaymentsReservationsActionIcons({ sourceId, id }: Props) {
  const { data } = useQuery(
    (q, { sourceId }) => {
      const entityActions = sourceId
        ? q
            .actions({
              order_by: [
                { schemaId: 'asc_nulls_last' },
                { createdAt: 'desc_nulls_last' },
              ],
              where: {
                status: { _neq: 'skipped' },
                sourceLinks: {
                  sourceId: { _eq: sourceId },
                },
                jobPlan: {
                  isCurrentOnConnection: { _eq: true },
                },
              },
              distinct_on: ['schemaId'],
            })
            .map<Action>((item) => ({
              id: item?.id || '',
              status: item?.status || '',
              type: item?.schema?.uniqueRef || '',
              message: item?.title || '',
              automationTemplateType: item.automation?.ttemplate?.type || '',
              automationTitle:
                item.automation?.title || item.automation?.ttemplate?.title,
            })) || []
        : [];

      const entityChanges = sourceId
        ? q
            .changes({
              order_by: [{ createdAt: 'desc_nulls_last' }],
              where: {
                status: { _neq: 'skipped' },
                isLatestVersion: {
                  _eq: true,
                },
                changeSourceLinks: {
                  sourceId: { _eq: sourceId },
                },
              },
            })
            .map<Action>((item) => ({
              id: item?.id || '',
              status: item?.status || '',
              title:
                toTitleCase(
                  item?.entityUniqueRef
                    ?.split('/')
                    .at(0)
                    ?.replace('create', '') || ''
                ) || '',
              type: item?.entityUniqueRef?.split('/').at(0) || '',
              message: item?.message || '',
              automationTemplateType: item.automation?.ttemplate?.type || '',
              automationTitle:
                item.automation?.title || item.automation?.ttemplate?.title,
            })) || []
        : [];

      const actions = [...entityActions, ...entityChanges];

      console.log('q', actions);

      return {
        actions,
        // changes,
      };
    },
    {
      variables: { sourceId, id },
      queryKey: ['payments', 'reservations'],
    }
  );

  const [results] = useDebouncedValue(data?.actions || [], 100);

  return (
    <Tooltip.Group openDelay={100} closeDelay={100}>
      <Avatar.Group spacing="xs">
        {results.map((task) => {
          return <Icon key={task.id} {...task} />;
        })}
      </Avatar.Group>
    </Tooltip.Group>
  );
}

const Icon = ({
  automationTemplateType,
  message,
  status,
  automationTitle,
  type,
}: Action) => {
  const isSuccess = status === 'ok' || status === 'completed';
  const { colors } = useMantineTheme();

  const color = isSuccess ? 'green' : 'red';

  return (
    <Tooltip
      multiline
      label={
        automationTitle ? (
          <div>
            {automationTitle}:
            <br />
            {type ? `${toTitleCase(type)} - ` : ''}
            {message || 'Failed'}
          </div>
        ) : (
          message
        )
      }
      maw={400}
      withArrow
      withinPortal
    >
      <Avatar
        size={'sm'}
        color={color}
        radius="xl"
        sx={(theme) => ({
          border: `1px solid ${theme.colors[color][2]}`,
        })}
      >
        <AutomationIcon
          size={15}
          templateType={automationTemplateType}
          color={isSuccess ? colors[color][8] : colors[color][6]}
          sx={{ flexShrink: 0 }}
        />
      </Avatar>
    </Tooltip>
  );
};

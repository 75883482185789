import { taxBehavior_enum } from '@finalytic/graphql';
import { formatCurrency } from '@finalytic/utils';

type Props = {
  centTotal: number;
  taxRate: number | string | null | undefined;
  taxBehavior: taxBehavior_enum;
  currency: string;
  markup: number;
  markupType: 'rate' | 'cent';
  markupTaxRate: number | string | null | undefined;
  markupTaxBehavior: taxBehavior_enum;
};

export const formatExpenseLineTotal = ({
  centTotal,
  currency,
  markup: m,
  markupTaxRate: mtr,
  markupTaxBehavior,
  markupType,
  taxRate: tr,
  taxBehavior,
}: Props) => {
  const formatTaxRate = (r: number | string | null | undefined) => {
    if (typeof r === 'number') return r / 100;
    if (typeof r === 'string') return parseFloat(r.replace('%', '')) / 100;
    return 1;
  };

  const lineTax =
    taxBehavior === 'included' ? 0 : centTotal * formatTaxRate(tr);
  const lineTotal = centTotal + lineTax;

  const markup = markupType === 'cent' ? m : lineTotal * (m / 100);
  const markupTax =
    markupTaxBehavior === 'included' ? 0 : markup * formatTaxRate(mtr);
  const markupTotal = markup + markupTax;

  const total = lineTotal + markupTotal;

  return formatCurrency(total, currency);
};

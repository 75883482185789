import { useMutation } from '@finalytic/data';
import { DeleteModal } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';
import { LegacyExpenseEditDrawer } from './LegacyExpenseEditDrawer';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  expense: Maybe<ExpenseRow>;
}

export const ExpenseEllipsisMenuModals = ({
  deleteModal,
  legacyEditDrawer,
  expense: initial,
}: {
  expense: ModalProps['expense'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  legacyEditDrawer: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const expense = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} expense={expense} />
      <LegacyExpenseEditDrawer
        closeDrawer={legacyEditDrawer.closeModal}
        opened={legacyEditDrawer.opened}
        expense={expense}
      />
    </>
  );
};

const Delete = ({ opened, closeModal, expense }: ModalProps) => {
  const expenseId = expense?.id;

  const { mutate } = useMutation(
    (q, args: { id: string }) => {
      return q.deleteTransaction({ id: args.id })?.id;
    },
    {
      successMessage: {
        message:
          'You successfully deleted the expense. If this was done in error, please get in touch with us.',
      },
      invalidateQueryKeys: ['expenses'],
    }
  );

  return (
    <DeleteModal
      opened={opened}
      onClose={closeModal}
      onSubmit={async () => {
        if (!expenseId) return;

        await mutate({
          args: {
            id: expenseId,
          },
        });
      }}
      title={'Delete this expense?'}
      subtitle={
        'Are you sure you want to delete this expense? This action cannot be undone.'
      }
    />
  );
};
